.main-content {
	background-position: bottom center;
	background-size: 100% 92%;
	.header {
		h1 {
			font-weight: bold;
			font-size: 58px;
			text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
		}
	}
	p {
		font-size: 26px;
		line-height: 1.4;
		margin: 20px 0;
	}
	.first-header {
		padding-bottom: 10px;
		h1 {
			font-size: 41px;
			span {
				color: #72E69F;
			}
		}
		.underline {
			margin: 7px 0;
		}
	}
}
.com-about {
	h1 {
		font-weight: bold;
		font-size: 70px;
		line-height: 0.8;
		margin-top: 40px;
		span {
			display: block;
		}
		span:nth-child(1) {
			font-weight: 300;
			font-size: 44px;
			margin-left: 4px;
			line-height: 1;
		}
		span:nth-child(2) {
			font-size: 135px;
			margin-left: -5px;
		}
		span:nth-child(3), span:nth-child(4) {
			line-height: 0.9;
		}
		span:nth-child(4), span:nth-child(2) {
			word-break: break-all;
		}
	}
	.btn-custom {
		text-align: left;
		padding: 15px 15px;
		font-size: 22px;
		font-weight: 300;
		span {
			font-weight: 700;
		}
		img {
			position: relative;
			top: -2px;
			margin-left: 15px;
		}
	}

}
#communities {
	h1 {
		span:nth-child(2) {
			word-break: break-all;
			font-size: 124px;
		}
	}
	.btn-custom {
		@include gradient-horizontal(rgba(119, 138, 212, 0.6), rgba(103, 97, 168, 0.6), 50%, 100%);
	}
}
.about-us {
	.btn-custom {
		@include gradient-horizontal(rgba(183, 224, 114, 0.4), rgba(101, 179, 95, 0.4), 50%, 100%);
	}
}


