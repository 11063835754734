.custom-navbar {
	max-width: 1920px;
	margin: 0 auto;
	padding: 0px 10px;
	box-shadow: none;
	background-color: transparent;
	@include transition(all 1s ease);
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;

	.navbar-brand {
		padding: 0px;

		img {
			padding: 10px 0px;
			height: 105px;
			@include transition(all 0.5s ease);
		}
	}

	.navbar-nav {
		padding: 10px 10px;

		.nav-link {
			background: linear-gradient(to right, #3B3B6E 0%, #5C4687 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: 900;
			font-size: 24px;
			line-height: 1;
		}

		.drop-menu {
			margin-left: 10px;

			.nav-link {
				font-weight: normal;
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}

	.navbar-toggler {
		margin-right: 10px;
		@include transition(all 0.5s ease);
	}

	.z-index-9 {
		z-index: 9999;
	}
}

.bg-menu {
	height: 100%;
	@include transition(all 0.5s ease);
}

.bg-menu:after {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	@include transition(all 0.5s ease);
	opacity: 1;
	@include gradient-horizontal(rgba(250, 220, 137, 0.9), rgba(228, 138, 133, 0.9), 50%, 100%);
}

.nav-closed:after {
	height: 70px;
	@include transition(all 0.5s ease);
}

.nav-closed:after {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	@include transition(all 0.5s ease);
	opacity: 0;
	@include gradient-horizontal(rgba(250, 220, 137, 0.9), rgba(228, 138, 133, 0.9), 50%, 100%);
}

.white-bg {
	background-color: rgba(72, 72, 72, 0.4);

	.navbar-brand {
		img {
			height: 70px;
		}
	}

	.navbar-toggler {
		margin-right: 0px;
	}
}

.white-bg-1 {
	background-color: rgba(72, 72, 72, 0.4);
}

/* Icon 1 */
.animated-icon1,
.animated-icon2,
.animated-icon3 {
	width: 30px;
	height: 20px;
	position: relative;
	margin: 0px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.animated-icon1 span {
	background: white;
}

.animated-icon1 span:nth-child(1) {
	top: 0px;
}

.animated-icon1 span:nth-child(2) {
	top: 10px;
}

.animated-icon1 span:nth-child(3) {
	top: 20px;
}

.animated-icon1.open span:nth-child(1) {
	top: 11px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
	opacity: 0;
	left: -60px;
}

.animated-icon1.open span:nth-child(3) {
	top: 11px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}