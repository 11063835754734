@media (max-width: $screen-sm) {
	.custom-navbar .navbar-brand {
		img {
			height: 60px;
		}
	}
	.white-bg {
		.navbar-brand {
			img {
				height: 50px;
			}
		}
	}
	.listingsWrap {
		padding: 0px;
	}
	.switchWrap {
		padding: 0px;
	}
	.listings-column {
		margin: 0px;
	}
	.listings {
		margin: 0px;
	}
	.team-member {
		margin-right: 15px;
		margin-left: 15px;
		.disc {
			width: 70%;
		}
	}
	.teamWrapper {
		.member-hover {
			position: static;
			margin-right: 15px;
			margin-left: 15px;
			-webkit-box-shadow: 0 8px 17px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19)!important;
		    box-shadow: 0 8px 17px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19)!important;
			width: auto;
			overflow: hidden;
			@include gradient-horizontal(rgba(122, 125, 238, 1), rgba(103, 200, 253, 1), 0%, 100%);
			margin-top: 10px;
			margin-bottom: 10px;
			.content {
				font-size: 12px;
				font-weight: 300;
				letter-spacing: 0.5px;
				opacity: 1;
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-o-transform: scale(1);
				-ms-transform: scale(1);
				transform: scale(1);
			}
		}
		.closed {
			height: 0px;
			padding: 0px 15px;
			@include transition(all 3s ease);
		}
		.active {
			padding: 10px 15px;
			height: 140px;
			@include transition(all 0.5s ease);
		}
	}
	.listings-column {
		.active {
			box-shadow: 
				inset 0 0 0 300px rgba(56, 117, 179, 0.8),
				inset 0 0 0 110px rgba(56, 117, 179, 0.2),
				0 1px 2px rgba(56, 117, 179, 0.9);
			.desc, .btn-custom {
				display: inline-block;
				-webkit-animation-duration: 1.2s;
			    animation-duration: 1.2s;
			    -webkit-animation-fill-mode: both;
			    animation-fill-mode: both;
			}
		}
	}
	.com-content .link {
	    margin-bottom: 0px;
	    padding: 7px 10px;
	}
	.switchWrap {
		.md-custom-select input.select-dropdown {
			font-size: 14px;
		}
		.md-custom-select .caret {
			right: 15px;
		}
	}
	.listingComment {
		min-height: 121px;
	}
	.img-caption-mobile {
		display: block;
	}

	#elm-team .team {
		padding-bottom: 200px;
	}
}
@media (min-width: 320px) and (max-width: $screen-sm) {
	#communities h1 span:nth-child(2) {
	    font-size: 155px;
	}
	.com-about h1 {
		font-size: 88px;
	}
}
@media (min-width: 576px) and (max-width: $screen-sm) {
	#community-list {
		.com-content {
			padding-left: 20px;
		}
	}
	.switchWrap {
		padding: 0px 17.5px;
	}
	.listingsWrap {
		padding: 0px 25px;
	}
	.listings-column {
		margin-right: 5px;
		margin-left: 5px;
		margin-bottom: 10px;
	}
	.listings-column {
		.listings {
			padding: 10px 15px;
			h4 {
				font-size: 16px;
			}
			p {
				font-size: 13px;
				margin-bottom: 3px;
			}
		}
		.desc, .btn-custom {
			font-size: 12px;
		}
		.btn-custom {
			padding: 3px 20px;
			margin-top: 0px;
		}
	}
}
@media (min-width: 598px) and (max-width: 1200px) {
	.desktop-spacing {
		padding-bottom: 30px;
    	padding-left: 20px;
	}
	.page-content {
		background-size: 100% 80%;
	}
	.com-content p {
		font-size: 18px;
	}
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
	.main-content p {
		width: 60%;
	}
	.page-content {
		background-size: 100% 85%;
	}
	#community-list {
		.com-content {
			padding-left: 20px;
			width: 100%;
		}
		.switchWrap {
			padding: 0 20px;
		}
		.listingsWrap {
			padding: 0px 30px 30px;
		}
	}
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
	.about-number {
		h3 {
			font-size: 95px;
		}
		p {
			font-size: 32px;
		}
	}
	.desktop-spacing {
		padding-bottom: 40px;
    	padding-left: 40px;
	}
	.main-content {
	    background-size: 100% 70%;
	}
	.page-content {
		background-size: 100% 83%;
	}
	.main-content .inner-content {
		width: 100%;
	}
	.contact-header h1 {
		font-size: 70px;
	}
	#community-list {
		.com-content {
			padding-left: 40px;
			width: 100%;
		}
		.switchWrap {
			padding: 0 40px;
		}
		.listingsWrap {
			padding: 0px 50px 30px;
		}
	}
}

@media (min-width: $screen-lg) and (max-width: 1475px) {
	.about-number {
		h3 {
			font-size: 130px;
		}
		p {
			font-size: 40px;
		}
	}
}


@media (min-width: 576px) and (max-width: 1200px) {
	.com-content {
		position: relative;
		z-index: 102;
	}
	.img-absolute {
		position: absolute;
	}
	.c-img-1 {
		left: 20%;
		top: 10%;
		right: 0;
		margin: auto;
		max-width: 430px;
	}
	.c-img-2 {
		max-width: 500px;
		left: auto;
		right: 5%;
		top: 30%;
		transform: translateY(0%);
	}
}