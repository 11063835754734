
#communities {
	
}

.community-bg-home {
	@include gradient-horizontal(rgba(151, 134, 255, 0.8), rgba(143, 255, 204, 0.8), 50%, 100%);
}

.com-bg-purple {
	@include gradient-horizontal(rgba(119, 73, 149, 0.6), rgba(109, 86, 182, 0.6), 50%, 100%);
}
.com-bg-green {
	@include gradient-horizontal(rgba(89, 147, 152, 0.6), rgba(57, 77, 119, 0.6), 50%, 100%);
}

.com-content {
	margin-top: 30px;
	h2, h1 {
		margin: 0px;
	}
	h1 {
		font-weight: 900;
		line-height: 0.8;
		margin-left: -5px;
	}
	h2 {
		line-height: 1;
		font-weight: 200;
		font-size: 32px;
	}
	p {
		font-weight: 400;
		font-size: 15px;
	}
	.link {
		margin-top: 20px;
		border: 1px solid white;
		display: inline-block;
		padding: 6px 25px; 
		border-radius: 3px;
		margin-bottom: 40px;
		a {
			font-size: 16px;
		}
		&:hover {
			a {
				background: linear-gradient(to right, #fff 0%, #fff 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}
}

.switch {
  	position: relative;
  	height: 26px;
  	width: 190px;
  	margin-left: 10px;
  	border-radius: 18px;
  	background-color: white;
}

.switch-label {
	color: #6ABDC0;
	font-weight: 700;
  	position: relative;
  	z-index: 2;
  	float: left;
  	width: 95px;
  	line-height: 26px;
  	font-size: 16px;
  	text-align: center;
  	cursor: pointer;
}
.switch-label:active {

}

.switch-label-off {
  	padding-left: 2px;
}

.switch-label-on {
  	padding-right: 2px;
}


.switch-input {
  	display: none;
}
.switch-input:checked + .switch-label {
	color: white;
	-webkit-transition: 0.15s ease-out;
	-moz-transition: 0.15s ease-out;
	-ms-transition: 0.15s ease-out;
	-o-transition: 0.15s ease-out;
	transition: 0.15s ease-out;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  	left: 95px;
  	/* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
	position: absolute;
	z-index: 1;
	top: 0px;
	left: 0px;
	display: block;
	width: 95px;
	height: 26px;
	border-radius: 18px;
	-webkit-transition: left 0.15s ease-out;
	-moz-transition: left 0.15s ease-out;
	-ms-transition: left 0.15s ease-out;
	-o-transition: left 0.15s ease-out;
	transition: left 0.15s ease-out;
	@include gradient-horizontal(rgba(229, 142, 92, 0.84), rgba(239, 182, 98, 0.84), 50%, 100%);
}

.switchWrap {
	padding: 0 100px;
	.title {
		font-weight: 400;
	}
	.md-custom-select {
		margin-left: 10px;
		margin-top: 0px;
		margin-bottom: 0px;
		@include gradient-horizontal(rgba(104, 156, 231, 1), rgba(71, 193, 195, 1), 50%, 100%);
		input.select-dropdown {
			height: 32px;
			color: white;
			font-size: 19px;
			min-width: 200px;
			line-height: normal;
		}
		input.select-dropdown:disabled {
			color: rgba(0,0,0,.3);
    		border-bottom-color: rgba(0,0,0,.3);
    		cursor: default;
		}
		.select-dropdown li.disabled>span {
			color: rgba(132, 132, 132, 0.61);
		}
		.select-dropdown {
			margin-bottom: 0px;
			border-bottom: none;
			text-align: center;
		}
		.caret {
			color: white;
			right: 20px;
			z-index: 99;
			top: 10px;
		}
	}
}
.listingsWrap {
	padding: 0 110px;
	padding-bottom: 30px;
}
.listingComment {
	padding: 20px 50px;
	font-size: 18px;
	color: white;
	font-weight: 300;
	line-height: 1.1;
	min-height: 121px;
}
.listingComment-1 {
	@include gradient-horizontal(rgba(52, 77, 122, 0.9), rgba(66, 152, 156, 0.9), 50%, 100%);
}
.listingComment-2 {
	@include gradient-horizontal(rgba(83, 20, 115, 0.9), rgba(119, 84, 199, 0.9), 50%, 100%);
}
.listings-column {
	margin-right: 5px;
	margin-left: 5px;
	margin-bottom: 10px;
}
.listings {
	.view {

	}
	padding: 15px 15px 0px 15px;
	box-shadow: 
			inset 0 0 0 300px rgba(41, 41, 41, 0.16),
			inset 0 0 0 0px rgba(41, 41, 41, 0.16),
			0 1px 2px rgba(41, 41, 41, 0.16);
	cursor: pointer;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	h4 {
	 	/*background: linear-gradient(to right, #E58E5D 0%, #EFB663 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;*/
		color: #EFB663;
		text-shadow: 2px 2px 4px rgba(0,0,0,.7);
		margin-bottom: 0px;
		line-height: 1;
		font-size: 21px;
		font-weight: 600;
	}
	p {
		font-size: 16px;
		line-height: 1.3;
		font-weight: 500;
		text-shadow: 2px 2px 4px rgba(0,0,0,.7);
	}
	.btn-custom {
		color: white;
		font-size: 16px;
		padding: 4px 25px;
		display: inline-block;
		margin-top: 5px;
		@include gradient-horizontal(rgba(201, 156, 67, 1), rgba(241, 206, 114, 1), 10%, 100%);
		img {
			position: relative;
			display: inline-block;
			top: -2px;
			margin-left: 15px;
		}
	}
	.desc, .btn-custom {
		display: none;
	}

	&:hover {
		box-shadow: 
			inset 0 0 0 300px rgba(56, 117, 179, 0.8),
			inset 0 0 0 110px rgba(56, 117, 179, 0.2),
			0 1px 2px rgba(56, 117, 179, 0.9);
		overflow-y: scroll !important;
		.desc, .btn-custom {
			display: inline-block;
			-webkit-animation-duration: 1.2s;
		    animation-duration: 1.2s;
		    -webkit-animation-fill-mode: both;
		    animation-fill-mode: both;
		}
		.direction-btn {
			display: block;
		}
	}
}

.direction-btn {
	display: none;
	position: absolute;
	top: 10px;
	right: 10px;

	a {
		display: inline-block;
		border-radius: 50%;
		border: 1px solid white;
		padding: 7px;
		background-color: rgba(0, 0, 0, 0.5);
    	box-shadow: 1px 1px 10px black;
	}
	img {
		width: 18px;
	}
}

.tab-content {
	padding: 0px;
	background-color: transparent;
}

.carousel {
	.carousel-control-next, .carousel-control-prev {
		opacity: 1;
		width: 6%;
	}
	.carousel-control-next-icon {
		background-image: url(/images/arrow_right.svg);
	}
	.carousel-control-prev-icon {
		background-image: url(/images/arrow_left.svg);
	}
}

.gm-style-iw {
	.gm-style-iw-d {
		overflow: visible !important;
	}
}
.gm-style-iw {
	padding: 0px !important;
	button {
		top: -5px !important;
    	right: -5px !important;
	}
}
.gm-style .gm-style-iw-c {
	z-index: 9;
}
.mapinfo {
	max-width: 180px;
	width: 180px;
	img {
		margin-bottom: 5px;
	}
	p, a {
		font-weight: bold;
		color: black;
		font-size: 14px;
	}
	a {
		border: 1px solid black;
		padding: 2px 25px;
		display: inline-block;
		margin: 5px 0;
	}
	.dir-btn {
	    margin: 0;
	    position: absolute;
	    top: 5px;
	    left: 5px;
	    border: 1px solid white;
	    background: rgba(0, 0, 0, 0.7);
	    box-shadow: 1px 1px 5px black;
	    padding: 5px;
	    border-radius: 50%;
		img {
			width: 17px;
    		margin-bottom: 0px;
		}
	}
}
@media (min-width: $screen-lg) {
	#community-6, #community-7 {
		margin-top: calc(-16.9% + 131px);
	}
}

@media (min-width: $screen-lg) {
	
}

// Phone
@media (max-width: $screen-xs) {
	.listings-column {
		.view {
			height: 270px;
		}
	}
}

// Phone
@media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
	.listings-column {
		.view {
			height: 200px;
		}
	}
}

// Small Screen
@media (min-width: $screen-md) and (max-width: $screen-md-max) {
	.listings-column {
		.view {
			height: 275px;
		}
	}
}

// Small Screen
@media (min-width: $screen-lg) and (max-width: $screen-xl-max) {
	.listings-column {
		.view {
			height: 280px;
		}
	}
}

@media (min-width: $screen-xl) and (max-width: 1652px) {
	.listings-column {
		.view {
			height: 255px;
		}
	}
}
