/* IE Version update TAB */
#VersionTab {
  z-index: 99999;
  position: absolute;
  top: 100px;
  color: red;
  width: 100%;
  text-align: center;
  font-size: 22px;
  background-color: yellow;
  height: 100px;
  line-height: 100px;

  a {
    color: blue;
  }
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.bg-center {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

body {
  background: rgb(218, 218, 218);
  color: black;
  max-width: 1920px;
  margin: 0 auto;
}

a {
  -webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition: color 300ms, background-color 300ms;
  transition: color 300ms, background-color 300ms;
}

.outline {
  display: none;
}

#success_message {
  display: none;
}