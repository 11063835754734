.about-us {
	.btn-custom {
		
	}
}

.about-number {
	margin-top: 15px;
	h3 {
		font-size: 44px;
		font-weight: 700;
		line-height: 1;
		margin: 0px;
	}
	p {
		font-size: 28px;
		line-height: 1;
		font-weight: 400;
	}
}

.about-bg-blue {
	@include gradient-horizontal(rgba(41, 70, 97, 0.84), rgba(27, 29, 63, 0.84), 50%, 100%);
}
.about-bg-green {
	@include gradient-horizontal(rgba(53, 105, 44, 0.84), rgba(31, 71, 48, 0.84), 50%, 100%);
}

.about-bg-purple {
	@include gradient-horizontal(rgba(88, 49, 130, 0.84), rgba(46, 18, 46, 0.84), 50%, 100%);
}


.team-member {
	background-color: #3E1962;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	.portrait {
		width: 30%;
	}
	.disc {
		padding-left: 15px;
		h4 {
			background: linear-gradient(to right, #7FC8FF 0%, #7A80E8 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		h4 {
			font-size: 22px;
			margin-bottom: 5px;
		}
		p {
			line-height: 1;
			font-weight: 200;
			color: #7FC8FF;
		}
	}
}
.teamWrapper {
	position: relative;
	.member-hover {
		/*@include gradient-horizontal(rgba(122, 125, 238, 1), rgba(103, 200, 253, 1), 0%, 100%);*/
		box-shadow: 
			inset 0 0 0 0 rgba(103,200,253, 0),
			inset 0 0 0 30px rgba(122,125,238, 0),
			0 1px 2px rgba(0,0,0,0);
		position: absolute;
		top: 0px;
		left: 5px;
		z-index: 99;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		padding: 20px 30px;
		
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		
		
		
		-webkit-backface-visibility: hidden; /*for a smooth font */
		.content {
			color: white;
			font-weight: 500;
			font-size: 14px;
			line-height: 1.2;
			opacity: 0;

			-webkit-transition: all 0.4s ease-in-out;
			-moz-transition: all 0.4s ease-in-out;
			-o-transition: all 0.4s ease-in-out;
			-ms-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;

			-webkit-transform: scale(0);
			-moz-transform: scale(0);
			-o-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
			img {
				margin-top: 5px;
				width: 28px;
				margin-right: 3px;
			}
		}
	}
	&:hover {
		.member-hover {
			box-shadow: 
				inset 0 0 0 110px rgba(103,200,253, 1),
				inset 0 0 0 70px rgba(122,125,238, 1),
				0 1px 2px rgba(122,125,238,1);
			.content {
				opacity: 1;
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-o-transform: scale(1);
				-ms-transform: scale(1);
				transform: scale(1);
			}
		}
	}
}
